<tr>
    <th class="pb-50 w-50">
      <span class="font-weight-bold">{{
        $t("fields.remarks")
      }}</span>
    </th>
    <td class="pb-50">
      {{ appData.data.date }}
    </td>
  </tr><template>
  <b-form @submit.prevent>
    <b-row>
      <b-col sm="12" md="3" class="p-0 m-0">
        <navlink></navlink>
      </b-col>
      <b-col sm="12" md="9">
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                :id="key"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col sm="12" md="6">
                    <inputtext
                      name="firstname"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.firstname')
                      "
                      :label="$t('fields.firstname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.firstname')
                      "
                      :valuex="appData.name"
                      @updatedata="(val) => (appData.name = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6">
                    <inputtext
                      name="lastname"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.lastname')
                      "
                      :label="$t('fields.lastname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.lastname')
                      "
                      :valuex="appData.lastname"
                      @updatedata="(val) => (appData.lastname = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <div>Email</div>
                    <div class="mt-1"></div>
                    {{ appData.email }}
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="phonenumber"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.phonenumber')
                      "
                      :label="$t('fields.phonenumber')"
                      placeholder="0000000000"
                      :valuex="appData.phonenumber"
                      @updatedata="(val) => (appData.phonenumber = val)"
                      validations="numeric|length:10"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="address"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.address')"
                      :label="$t('fields.address')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.address')
                      "
                      :valuex="appData.address"
                      @updatedata="(val) => (appData.address = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="city"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.city')"
                      :label="$t('fields.city')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.city')
                      "
                      :valuex="appData.city"
                      @updatedata="(val) => (appData.city = val)"
                      validations="required"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.state") }}</label>
                    <inputselect
                      name="state"
                      :tooltip="$t('fields.select') + ' ' + $t('fields.state')"
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.state')
                      "
                      :valuex="appData.state"
                      keys="key"
                      @updatedata="(val) => (appData.state = val)"
                      :options="state"
                      id="state"
                      validations="required"
                    ></inputselect>
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.dateofbirth") }}</label>
                    <flat-pickr
                      v-model="appData.dob"
                      :config="{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        maxDate: today,
                      }"
                      locale="en-In"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>
                  <!-- <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.bloodgrouptype") }}</label>
                    <inputselect
                      name="bloodgrouptype"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.bloodgrouptype')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.bloodgrouptype')
                      "
                      :valuex="appData.bloodgroup"
                      keys="key"
                      @updatedata="(val) => (appData.bloodgroup = val)"
                      :options="bloodgrouptype"
                      id="bloodgroup"
                      validations="required"
                    ></inputselect>
                  </b-col> -->
                  <!-- <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="allergies"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.allergies')
                      "
                      :label="$t('fields.allergies')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.allergies')
                      "
                      :valuex="appData.allergies"
                      @updatedata="(val) => (appData.allergies = val)"
                      validations=""
                    ></inputtext>
                  </b-col> -->
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.languagepreference") }}</label>
                    <inputselect
                      name="languagepreference"
                      :tooltip="
                        $t('fields.select') +
                        ' ' +
                        $t('fields.languagepreference')
                      "
                      :placeholder="
                        $t('fields.select') +
                        ' ' +
                        $t('fields.languagepreference')
                      "
                      :valuex="appData.language"
                      keys="key"
                      @updatedata="(val) => (appData.language = val)"
                      :options="['English', 'Hindhi']"
                      id="languagepreference"
                      validations="required"
                    ></inputselect>
                  </b-col>
                  <!-- <b-col sm="12" md="6" lg="6">
                    <validation-provider
                      #default="validationContext"
                      name="Upload File"
                      rules=""
                    >
                      <b-form-group
                        :label="$t('fields.uploadphoto')"
                        label-for="fileinput"
                      >
                        <b-form-file
                          accept="image/x-png,image/gif,image/jpeg"
                          id="fileinput"
                          name="uploadphoto "
                          :placeholder="$t('fields.fileupload')"
                          drop-placeholder="Drop file here..."
                          @change="updateValue($event)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col> -->
                </b-row>
                <div class="d-flex float-right mt-2">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="mr-1"
                    variant="secondary"
                    @click="$router.go(-1)"
                  >
                    <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                    <span class="align-middle">{{ $t("fields.back") }} </span>
                  </b-button>
                  <!-- <buttoncomp
                    :todetails="{ name: 'settings-profile-list' }"
                    classx=" bg-primary float-end"
                    name="View"
                    class="mx-1"
                    type="routerlink"
                  ></buttoncomp> -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary                            "
                    class="mr-1"
                    type="submit"
                  >
                    <feather-icon icon="Edit3Icon" class="mr-25" />
                    {{ $t("fields.save") }}
                  </b-button>
                </div>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
// import buttoncomp from "@/views/Component/Input/button.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import inputcleave from "@/views/Component/Input/inputcleave.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import navlink from "../navlink.vue";
export default {
  components: {
    BCol,
    BCard,
    navlink,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    // buttoncomp,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    inputcleave,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "settinigs-profile";
    const modulename = "Profile";
    const modulenamesub = "profile";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let today = new Date();
    var self = this;
    let key = "123";
    let edittype = 1;
    let storetype = "editData";
    let iddata = router.currentRoute.params.id;
    const blankAppData = {
      firstname: "",
      lastname: "",
      state: "",
      city: "",
      dob:"",
      email: "",
      phonenumber: "",
      language: "",
      bloodgroup:"",
      currency: "",
      address:"",
      allergies:"",
      status: 1,
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();
      formData.append("name", temp.name);
      formData.append("lastname", temp.lastname);
      formData.append("phonenumber", temp.phonenumber);
      formData.append("language", temp.language);
      formData.append("city", temp.city);
      formData.append("dob", temp.dob);
      formData.append("address", temp.address);
      formData.append("allergies", temp.allergies);
      formData.append("bloodgroup", temp.bloodgroup);
      formData.append("state", temp.state);
      if (temp.fileData.file) {
        let file = temp.fileData.file;
        formData.append("file", file);
      }
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/" + storetype, formData)
        .then((response) => {
          if (response.data.success) {
            var userData = JSON.parse(localStorage.getItem("userData"));
            var tempx = response.data.data;
            userData.data = tempx.data;
            userData.name = tempx.name;
            localStorage.setItem("userData", JSON.stringify(userData));
            toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                text:
                  modulename +
                  (edittype == 1 ? " editted" : " added") +
                  " successfully",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            // router
            //   .push({
            //     name: GENAPP_APP_STORE_MODULE_NAME,
            //     params: {
            //       id: response.data.id,
            //     },
            //   })
            //   .catch(() => {});
          } else if (response.data.success == 0) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                variant: "error",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
                params: {
                  id: response.data.id,
                },
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
          if (response.data && error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };
    const state = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchestate")
      .then((response) => {
        state.value = response.data.data;
      });

    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData")
      .then((response) => {
        if (!response.data.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          router
            .push({
              name: GENAPP_APP_STORE_MODULE_NAME,
            })
            .catch(() => {});
        }
        appData.value = response.data.data.data;
        appData.value.email = response.data.data.email;
        appData.value.name = response.data.data.name;
        appData.value.lastname = response.data.data.data.lastname;
        (appData.value.fileData = {
          name: "",
          file: "",
          size: "",
          type: "",
        })((self.key = Math.random()));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          appData.value = undefined;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 500) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Input fields missing values",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 422) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Please refresh your page",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      });
    const bloodgrouptype = ref([
      "A+",
      "A-",
      "B+",
      "B-",
      "AB+",
      "AB-",
      "O+",
      "O-",
    ]);
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      state,
      today,
      bloodgrouptype,
    };
  },
  mounted() {},
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.locale = newVal;
      this.$i18n.locale = newVal;
      localize(this.locale);
      this.key = Math.random().toString() + "asd";
      this.$refs.refFormObserver.validate();
    },
  },
  methods: {
    updatedetails(tit, val) {
      if (tit == "state") {
        this.appData.stateid = val.id;
        this.appData.state = val.name;
      }
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
